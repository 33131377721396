<template>
  <div>
    <div class="d-flex" style="min-height: 400px">
      <div class="row align-items-center m-5">
        <div class="col col-sm-3">
          <EmojiTriste class="emoji" />
        </div>
        <div class="col">
          <h1>Loja desconhecida</h1>
          <p class="mb-0">
            Não foi possível localizar a loja que você estava buscando. Talvez
            esta loja não exista mais ou tenha sido desativada.
          </p>
        </div>
      </div>
    </div>
    <Rodape />
  </div>
</template>

<script>
import EmojiTriste from "@/components/shared/imagens/EmojiTriste.vue";
import Rodape from "@/components/shared/Rodape.vue";

import { useHead } from "@vueuse/head";

export default {
  name: "LojaDesconhecida",
  components: {
    EmojiTriste,
    Rodape,
  },
  setup() {
    useHead({
      title: "Loja não encontrada | Loja MEI Grátis",
      meta: [
        {
          name: "robots",
          content: "noindex",
        },
      ],
      link: [
        {
          id: "favicon",
          rel: "icon",
          href: "/favicon.png",
        },
      ],
    });
  },
};
</script>

<style scoped>
.emoji {
  --cor-tema: #47b3c1;
  width: 120px;
  height: 120px;
}
</style>